<template>
  <div class="">
    <!--Loading hostaway export spinner-->
    <div v-if="isLoading" class="loading-overlay">
      <div class="loading-spinner">
        <b-spinner></b-spinner>
      </div>
    </div>
    <!--Breadcrumb button-->
    <breadcrumb-buttons hide-settings>
      <template #default>
        <div class="flex-container">
          <b-button id="columns-button" variant="primary" class="btn-icon" v-b-tooltip v-bind:title="$t('Columns')">
            <i class="fa-solid fa-bars"></i>
          </b-button>
          <b-button id="importPictures" variant="primary" class="btn-icon" v-b-tooltip
                    v-bind:title="$t('Upload images')" @click="openImageModal">
            <i class="fa-solid fa-image"></i>
          </b-button>

        </div>

      </template>
    </breadcrumb-buttons>
    <!--Filters-->
    <b-card no-body>
      <b-badge slot="header" variant="warning" @click="toggleCollapse">
        <!-- You can customize the header content here -->
        {{ filterText }}
      </b-badge>
      <b-collapse v-model="isCollapsed">
        <b-card-header class="pb-50">
          <b-row no-gutters class="w-100">
            <b-col>
              <h5>{{ msg("Filters") }}</h5>
            </b-col>
            <b-col>
              <div class="d-flex justify-content-end">
                <b-link class="mr-1" style="color: inherit" @click="resetFilters">
                  <feather-icon icon="XIcon" size="16"/>
                </b-link>
                <b-link style="color: inherit" @click="refresh()">
                  <feather-icon icon="RefreshCcwIcon" size="16"/>
                </b-link>
              </div>
            </b-col>
          </b-row>
        </b-card-header>
        <b-card-body>
          <b-row>
            <!-- Id -->
            <b-col cols="12" md="4" class="mb-md-0 mb-2">
              <label>{{ msg("Id") }}</label>
              <b-form-input
                  v-model="filter.like.id"
                  class="d-inline-block mr-1"
              />
            </b-col>
            <!--Address-->
            <b-col cols="12" md="4" class="mb-md-0 mb-2">
              <label>{{ msg("Address") }}</label>
              <b-form-input
                  v-model="filter.like.address"
                  class="d-inline-block mr-1"
              />
            </b-col>
            <!-- House number-->
            <b-col cols="12" md="4" class="mb-md-0 mb-2">
              <label>{{ msg("House number") }}</label>
              <b-form-input
                  v-model="filter.like.house"
                  class="d-inline-block mr-1"
              />
            </b-col>
            <!-- Zip-->
            <b-col cols="12" md="4" class="mb-md-0 mb-2">
              <label>{{ msg("Zip") }}</label>
              <b-form-input
                  v-model="filter.like.zip"
                  class="d-inline-block mr-1"
              />
            </b-col>
            <!--City-->
            <b-col cols="12" md="4" class="mb-md-0 mb-2">
              <label>{{ msg("City") }}</label>
              <b-form-input
                  v-model="filter.like.city"
                  class="d-inline-block mr-1"
              />
            </b-col>


            <b-col cols="12" md="6" lg="4">

              <b-form-group :label="msg('Estate agents')" label-for="realEstate-branch">
                <v-select
                    multiple

                    :dir="isRTL ? 'rtl' : 'ltr'"
                    :clearable="true"
                    v-model="filter.in.userIds"
                    :options="allAgents"
                    class="w-100"
                    label="name"
                    :reduce="(branch) => branch.id"

                />
              </b-form-group>
            </b-col>

            <!-- Rooms-->
            <b-col cols="12" md="4" class="mb-md-0 mb-2">
              <label>{{ msg("Rooms") }}</label>
              <b-form-input
                  v-model="filter.eq.realEstateArea.rooms"
                  class="d-inline-block mr-1"
              />
            </b-col>
            <!-- Living space-->
            <b-col cols="12" md="4" class="mb-md-0 mb-2">
              <label>{{ msg("Living space") }}</label>
              <b-form-input
                  v-model="filter.eq.realEstateArea.livingSpace"
                  class="d-inline-block mr-1"
              />
            </b-col>
            <!-- Total space-->
            <b-col cols="12" md="4" class="mb-md-0 mb-2">
              <label>{{ msg("Total space") }}</label>
              <b-form-input
                  v-model="filter.eq.realEstateArea.totalSpace"
                  class="d-inline-block mr-1"
              />
            </b-col>

            <!--Buy/Rent-->
            <b-col cols="12" md="4" class="mb-md-0 mb-2">
              <b-form-group :label="msg('Buy/Rent')" label-for="realEstate-branch">
                <v-select
                    :dir="isRTL ? 'rtl' : 'ltr'"
                    :clearable="true"
                    v-model="filter.eq.marketingType"
                    :options="[{value:{purchase:false,rent:true},label:msg('Rent')},{value:{purchase:true,rent:false},label:msg('Purchase')}]"
                    class="w-100"
                    label="label"
                    :reduce="(branch) => branch.value"
                />
              </b-form-group>
            </b-col>
            <!--Usage-->
            <b-col cols="12" md="4" class="mb-md-0 mb-2">
              <b-form-group :label="msg('Usage')" label-for="realEstate-branch">
                <v-select
                    :dir="isRTL ? 'rtl' : 'ltr'"
                    :clearable="true"
                    v-model="filter.eq.usageType"
                    :options="[
                  {value:{business:false,investment:false,living:true,waz:false},label:msg('Living')},
                  {value:{business:true,investment:false,living:false,waz:false},label:msg('Business')},
                  {value:{business:false,investment:true,living:false,waz:false},label:msg('Investment')}]"
                    class="w-100"
                    label="label"
                    :reduce="(branch) => branch.value"
                />
              </b-form-group>
            </b-col>
            <!--Nuki status-->
            <b-col cols="12" md="4" class="mb-md-0 mb-2">
              <b-form-group :label="msg('Nuki status')" label-for="realEstate-branch">
                <v-select
                    multiple
                    :dir="isRTL ? 'rtl' : 'ltr'"
                    :clearable="true"
                    v-model="filter.in.nukiStatus"
                    :options="[
                      {value:null,label:msg('NODEVICE')},
                      {value:'INSTALLED',label:msg('INSTALLED')},
                      {value:'TOINSTALL',label:msg('TOINSTALL')},
                      {value:'FAULT',label:msg('FAULT')},
                  ]"
                    class="w-100"
                    label="label"
                    :reduce="(branch) => branch.value"

                />
              </b-form-group>
            </b-col>

            <!--featured status-->
            <b-col cols="12" md="4" class="mb-md-0 mb-2">
              <b-form-group :label="msg('Advertised')" label-for="realEstate-branch">
                <v-select
                    :dir="isRTL ? 'rtl' : 'ltr'"
                    :clearable="true"
                    v-model="filter.eq.advertise"
                    :options="[
                                {value:true,label:msg('YES')},
                                {value:false,label:msg('NO')}
                            ]"
                    class="w-100"
                    label="label"
                    :reduce="(branch) => branch.value"

                />
              </b-form-group>
            </b-col>

            <!--featured status-->
            <b-col cols="12" md="4" class="mb-md-0 mb-2">
              <b-form-group :label="msg('Featured status')" label-for="realEstate-branch">
                <v-select
                    multiple
                    :dir="isRTL ? 'rtl' : 'ltr'"
                    :clearable="true"
                    v-model="filter.eq.featureStatus"
                    :options="[
                                {value:{propertyPage:true},label:msg('PROPERTYPAGE')},
                                {value:{searchPage:true},label:msg('SEARCHPAGE')},
                                {value:{homePage:true},label:msg('HOMEPAGE')},
                            ]"
                    class="w-100"
                    label="label"
                    :reduce="(branch) => branch.value"

                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="4" class="mb-md-0 mb-2">
              <label>{{ msg("RentTotal") }} bigger than </label>
              <b-form-input
                  v-model="filter.gte.realEstatePrices.rentTotal"
                  class="d-inline-block mr-1"
              />
            </b-col>

            <!-- Rooms-->
            <b-col cols="12" md="4" class="mb-md-0 mb-2">
              <label>{{ msg("RentNetto") }} bigger than</label>
              <b-form-input
                  v-model="filter.gte.realEstatePrices.rentNetto"
                  class="d-inline-block mr-1"
              />
            </b-col>


            <b-col cols="12" md="4" class="mb-md-0 mb-2">
              <label>{{ msg("RentTotal") }} less than </label>
              <b-form-input
                  v-model="filter.lte.realEstatePrices.rentTotal"
                  class="d-inline-block mr-1"
              />
            </b-col>

            <!-- Rooms-->
            <b-col cols="12" md="4" class="mb-md-0 mb-2">
              <label>{{ msg("RentNetto") }} less than</label>
              <b-form-input
                  v-model="filter.lte.realEstatePrices.rentNetto"
                  class="d-inline-block mr-1"
              />
            </b-col>

            <!-- Rooms-->
            <b-col cols="12" md="4" class="mb-md-0 mb-2">
              <label>{{ msg("HostawayId") }}</label>
              <b-form-input
                  v-model="filter.eq.hostawayId"
                  class="d-inline-block mr-1"
              />
            </b-col>
            <!-- RealEstate type -->
            <b-col cols="12" md="4" lg="4">
              <b-form-group :label="msg('Room category')" label-for="realEstate-type">
                <v-select
                    :dir="isRTL ? 'rtl' : 'ltr'"
                    v-model="filter.eq.imageCategory"
                    :options="[
                  {value:'B2',label:('Basic Zweizimmer')},
                  {value:'D2',label:('Deluxe Zweizimmer')},
                  {value:'B1',label:('Basic Einzimmer')},
                  {value:'D1',label:('Deluxe Einzimmer')},
                  ]"
                    label="label"
                    :reduce="(status) => status.value"
                    class="w-100"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
      </b-collapse>
    </b-card>

    <!--Data-->
    <b-card no-body>
      <div class="card-header" style="overflow: hidden">
        <span class="card-title m-0">{{ msg('Real Estates') }}</span>
        <b-col>
          <div class="d-flex justify-content-end">
            <b-link style="color: inherit" @click="refresh()">
              <feather-icon icon="RefreshCcwIcon" size="16"/>
            </b-link>
          </div>
        </b-col>
      </div>
      <!--Card Content-->
      <b-card-body>
        <div class="">
          <!-- Table Top -->
          <b-row no-gutters class="mb-1">
            <!-- Per Page -->
            <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
              <label>{{ msg('Show') }}</label>
              <v-select
                  v-model="page.amount"
                  @option:selected="refresh"
                  :dir="isRTL ? 'rtl' : 'ltr'"
                  :options="[10, 25, 50, 100]"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
              />
              <label>{{ msg('entries') }}</label>
            </b-col>
            <!-- Search -->
            <b-col cols="12" md="6">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                    v-model="filter.like.search"
                    class="d-inline-block"
                    :placeholder="msg('Search')"
                />
                <span class="mr-1"></span>
                <b-button variant="primary" @click="onRealEstateSelect(0)">
                  <span class="text-nowrap">{{ msg('New real estate') }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
        <!--Table-->
        <b-table
            ref="deletedRealEstate-table"
            striped
            hover
            responsive
            v-bind:table-class="{'action-active':selectedRealEstates.length > 0}"
            v-bind:items="realEstates"
            v-bind:fields="selectedFields"
            :busy="loading"
            @sort-changed="onSort"
            @row-clicked="onRowClick"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mx-1"></b-spinner>
              <strong>{{ msg('Loading') }}...</strong>
            </div>
          </template>
          <!--Actions-->
          <template #thead-top="data">
            <tr v-if="selectedRealEstates.length > 0">
              <th>
                <b-form-checkbox class="d-inline-block" v-bind:checked="isAllRealEstatesSelected"
                                 v-bind:indeterminate="!isAllRealEstatesSelected && selectedRealEstates.length>0"
                                 @change="toggleAllRealEstates"
                />
              </th>
              <th :colspan="data.columns - 3">
                <div>
                  <b-button variant="success" size="sm" class="mr-1" @click="bulkEnableRealEsates">
                    <i class="fas fa-play mx-1"></i>{{ msg('Activate') }}
                  </b-button>
                  <!--                  <b-button variant="danger" size="sm" class="mr-1">
                                      <i class="fas fa-stop mx-1"></i>{{ msg('Disable') }}
                                    </b-button>-->
                  <b-button disabled variant="warning" size="sm" class="mr-1">
                    {{ msg('Advertise') }}
                  </b-button>
                  <b-badge variant="secondary" size="sm" class="mr-1">
                    <span>selected:</span> <span>{{ selectedRealEstates.length }}</span>
                  </b-badge>


                </div>
              </th>
              <th></th>
            </tr>
          </template>
          <!--Head selected-->
          <template v-if="canReadActiveRealEstates" #head(select)>
            <b-form-checkbox v-bind:checked="isAllRealEstatesSelected"
                             v-bind:indeterminate="!isAllRealEstatesSelected && selectedRealEstates.length>0"
                             @change="toggleAllRealEstates"
            />
          </template>

          <template #cell(updatedDate)="data">
            {{ $moment(data.value, 'YYYY-MM-DD HH:mm').format("DD/MM/YY HH:mm") }}
          </template>
          <!--Cell selected-->
          <template v-if="canReadActiveRealEstates" #cell(select)="data">
            <b-form-checkbox
                v-model="selectedRealEstatesIds" :value="realEstates[data.index].id"
            />
          </template>
          <template #cell(attachments)="data">
            <b-img
                v-bind=" { blank: (data.value == null || data.value[0] == null), blankColor: '#777', width: 50, height: 50,
                                src:(data.value != null && data.value[0] != null ? mergeSrcUrl(getRealEstatesWebSite,data.value[0]) : null)  }"
                blank-color="#475F7B"
                rounded
                alt="Rounded image"
                class="d-inline-block m-1"
            />
          </template>

          <template #cell(realEstatePrices)="data">
            <div>
              {{ getPrices(data) }}
            </div>
          </template>

          <template #cell(status)="data" class="p-0">
            <b-badge class="mb-1 mr-1" :variant="statusVariants(data.value)">Status: {{ data.value }}</b-badge>

            <b-badge class="mb-1 mr-1" :variant="data.item.nukiStatus!=null?'primary':'danger'">Nuki:
              {{ data.item.nukiStatus != null ? msg(data.item.nukiStatus) : "Kein Gerät" }}
            </b-badge>

            <b-badge :variant="data.item.advertise !=null && data.item.advertise===true ?'success':'danger'">
              Inserat: {{ getFeaturedStatus(data.item) }}
            </b-badge>
          </template>

          <template #cell(userIds)="data">
            <div v-for="userId in data.value" v-bind:key="userId">
              <b-badge class="mb-1" variant="info">{{ getUserFullName(userId) }}</b-badge>
            </div>
          </template>
          <template #cell(address)="data">
            <div>
              <div style="white-space: nowrap">
                <span class="d-inline-block">{{ getFullAddress(data.item) }}</span>
              </div>
              <div style="margin-left: 5px">
                <strong>{{ msg('Zip') }}: </strong>{{ data.item.zip }}
              </div>
            </div>
          </template>
          <template #cell(id)="data">
            <b-button style="width: 95px" variant="outline-primary"
                      size="sm"
                      v-clipboard:copy="data.value"
                      v-clipboard:success="onCopy"
                      v-clipboard:error="onError"
                      class="d-block">
              <i class="fas fa-copy"> </i> ID
            </b-button>

            <b-button
                :disabled="isButtonDisabled(data.item)"
                style=""
                variant="outline-success"
                size="sm"
                @click="info(data.item, data.index, $event.target)"
                class="btn d-block mt-1 mb-1"
                v-b-tooltip
                :title="tooltipText(data.item)"
            >
              {{ msg('Activate') }}
            </b-button>
          </template>
        </b-table>

        <b-modal :id="infoModal.id" title="Confirmation" @ok="activateRealEstate(infoModal.propertyId)"
                 ok-variant="success" @hide="resetInfoModal">
          <p class="my-4"> {{ msg('activate?') }}</p>
          <p class="my-4"> {{ '(' + addressName + ')' }}</p>
        </b-modal>


        <b-modal size="lg" :id="featureInfoModal.id"
                 :title="msg('Confirmation')"
                 :ok-title="featureInfoModal.type==='NONE'? msg('Deactivate'):
                 (featureInfoModal.type==='NORMAL'? msg('Activate for free') : msg('Chargeable activation'))"
                 @ok="featureRealEstateById(featureInfoModal.propertyId,featureInfoModal.type)"
                 ok-variant="primary" @hide="resetFeatureInfoModal">

          <div class="w-100" style="text-align: center">
            <p class="mb-3" style="color:white;font-size: x-large;font-weight: bold;">Inserat Optionen</p>
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-row class="features-group">
                <b-col cols="6" class="mb-1">
                  <b-form-radio name="features-group" :aria-describedby="ariaDescribedby"
                                class="w-100"
                                value="NONE" v-model="featureInfoModal.type"
                                button-variant="outline-danger" button>
                    <div class="text-danger">
                      <p class="mb-0"><i class="far fa-stop-circle"></i></p>
                      <p>Nicht Inserieren</p>
                    </div>
                  </b-form-radio>
                </b-col>

                <b-col cols="6" class="mb-1">
                  <b-form-radio name="features-group"
                                :aria-describedby="ariaDescribedby"
                                value="NORMAL"
                                v-model="featureInfoModal.type"
                                button-variant="outline-primary" button>
                    <div>
                      <p class="mb-0"><i class="far fa-play-circle"></i></p>
                      <p>Normales Inserieren (Kostenlos)</p>
                    </div>
                  </b-form-radio>
                </b-col>


                <b-col cols="6" class="mb-1">
                  <b-form-radio name="features-group"
                                :aria-describedby="ariaDescribedby"
                                v-model="featureInfoModal.type"
                                value="PROPERTYPAGE"
                                button-variant="outline-success" button>
                    <div class="text-success">
                      <p class="mb-0"><i class="fas fa-tags"></i></p>
                      <p class="mb-0">Ausgewählte Immobilien- Immobilienseite</p>
                      <p class="mb-0 font-weight-bold"><strong>€11/30 Tage</strong></p>
                    </div>
                  </b-form-radio>
                </b-col>

                <b-col cols="6" class="mb-1">
                  <b-form-radio name="features-group"
                                :aria-describedby="ariaDescribedby"
                                v-model="featureInfoModal.type"
                                value="SEARCHPAGE" button-variant="outline-success" button>
                    <div class="text-success">

                      <p class="mb-0"><i class="fas fa-coins"></i></p>
                      <p class="mb-0"> Ausgewählte Immobilien- Suchseite</p>
                      <p class="mb-0 font-weight-bold"><strong>€17/30 Tage</strong></p>

                    </div>
                  </b-form-radio>
                </b-col>

                <b-col cols="12" class="mb-1">
                  <b-form-radio name="features-group" value="HOMEPAGE" :aria-describedby="ariaDescribedby"
                                v-model="featureInfoModal.type"
                                button-variant="outline-success" button>
                    <div class="text-success">

                      <p class="mb-0"><i class="far fa-gem"></i></p>
                      <p class="mb-0"> Ausgewählte Immobilien- Startseite</p>
                      <p class="mb-0 font-weight-bold"><strong>€60/30 Tage</strong></p>

                    </div>
                  </b-form-radio>
                </b-col>
              </b-row>


            </b-form-group>


          </div>
        </b-modal>

        <!--Table Paginator-->
        <div class="text-center w-100">
          <div class="d-inline-block">
            <b-pagination
                v-model="page.currentPage"
                :total-rows="page.total"
                :per-page="page.amount"
                first-number
                last-number
                @input="refresh"
            />
          </div>
          <div class="d-inline-block px-2"><strong>{{ msg('Total') }}</strong> {{ page.total }}</div>
        </div>
      </b-card-body>
    </b-card>
    <b-modal no-fade v-model="dialogs.realEstate" size="xl" ref="realEstate-modal" id="realEstate-modal"
             :hide-footer="true" no-close-on-backdrop centered>
      <realEstate-panel ref="realEstatePanel" @submitted="onSubmitted"/>
      <div class="text-center" style="justify-content:center">
        <b-button variant="outline-primary" class="btn-dialog" @click="submitRealEstate">
          {{ msg('Save') }}
        </b-button>
      </div>
    </b-modal>

    <!--Columns popover-->
    <b-popover size="xl" target="columns-button" triggers="click" placement="bottom" @show="dismissAllPopovers">
      <template #title>{{ msg("Columns") }}</template>
      <div style="max-width: 800px">
        <div v-if="fields!=null&& fields.length>0">
          <b-form-checkbox class="custom-control-inline mb-1" v-for="field in fields" v-model="field.active"
                           v-bind:key="field.key">{{ field.label }}
          </b-form-checkbox>
        </div>
      </div>
    </b-popover>

    <template v-if="realEstates!=null">
      <b-modal hide-footer no-close-on-backdrop
          v-model="dialogs.importimages"
          title="Import Images"
          size="lg"
          centered
          body-class="p-4"
          footer-class="d-flex justify-content-end"
          class="shadow-lg rounded"
      >
        <!-- Modal Body -->
        <div class="mb-4">
          <h5 class="text-muted mb-3">Select Real Estates</h5>
          <b-list-group>
            <b-list-group-item
                v-for="realEstate in realEstates"
                :key="realEstate.id"
                class="d-flex align-items-center justify-content-between"
            >
              <span>{{ realEstate.fullAddress }} ({{ realEstate.name }})</span>
              <b-form-checkbox
                  v-model="selectedRealEstateIds"
                  :value="realEstate.id"
              ></b-form-checkbox>
            </b-list-group-item>
          </b-list-group>
        </div>

        <!-- Dropzone (visible only if at least one real estate is selected) -->
        <div
            v-if="selectedRealEstateIds.length > 0"
            class="mt-4 p-3 border rounded shadow-sm"

        >
          <vue-dropzone
              id="dropzone"
              ref="dropzone"
              :options="dropzoneOptions"
              :useCustomSlot="true"
              :duplicateCheck="true"
              @vdropzone-complete="uploadComplete"
              @vdropzone-success="uploadSuccess"
              class="dropzone-modern"
          >
            <div class="dropzone-custom-content">
              <h3 class="dropzone-custom-title text-center">
                Drag and drop to upload content!
              </h3>
              <div class="subtitle text-center">
                ...or click to select a file from your computer
              </div>
            </div>
          </vue-dropzone>
        </div>

      </b-modal>
    </template>

  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import RealEstatePanel from './realEstatePanel'
import BreadcrumbButtons from "@/components/widgets/breadcrumbButtons.vue";
import mixinFilter from "@/mixins/mixinFilter";
import vue2Dropzone from 'vue2-dropzone'

export default {
  name: 'RealEstate',
  mixins: [mixinFilter],

  components: {
    BreadcrumbButtons,
    RealEstatePanel,
    vueDropzone: vue2Dropzone,

  },
  data() {
    return {
      isLoading: false,

      loading: true,
      amount: 10,
      buid: null,
      selectedRealEstates: [],
      realEstates: [],
      isCollapsed: false,
      selectedRealEstateIds: [],
      fields: [],
      filter: {
        like: {search: '', name: "", shortDescription: ""},
        eq: {type: "", realEstateArea: {}},
        gte: {realEstatePrices: {}},
        lte: {realEstatePrices: {}},
        in: {}
      },
      dropzoneOptions: {},

      sort: {
        by: 'id',
        desc: true,
        direction: 'desc'
      },
      page: {
        amount: 10,
        currentPage: 1,
        total: 0
      },
      dialogs: {realEstate: false, importimages: false},
      addressName: null,
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },
      featureInfoModal: {
        id: 'feature-modal',
        type: null,
        title: '',
        content: ''
      },
      //Listeners
      listeners: {
        refresh: () => {
        }
      }

    }
  },
  watch: {
    selectedRealEstateIds: {
      handler() {
        this.dropzoneOptions.headers =
            {
              'Authorization': `Bearer ${localStorage.Bearer}`,
              'realEstateIds': this.selectedRealEstateIds.join(',')
            }
      }
    },
    filter: {
      deep: true,
      handler() {
        this.refresh()
      }
    }
    , fields: {
      deep: true,
      handler() {
        localStorage["real-estates-fields"] = JSON.stringify(this.fields);
      }
    },
    selectedRealEstates: {
      handler() {
        this.$emit("selectedRealEstates", [...this.selectedRealEstates])
      }
    }
  },
  computed: {
    ...mapGetters('data', ['getOrganizationWebSite', 'getRealEstatesWebSite']),
    ...mapGetters('data', ['getUserFullName', 'getUserAcronyms']),
    ...mapGetters('data', ['getAllUsers', 'getUsersInTeam', 'getUserRole', 'getRoles', 'getRoleName']),
    ...mapGetters('realEstate', ['allStatusNoActive', 'statusVariants', 'getPrices', 'getFullAddress', 'getFeaturedStatus', 'canReadActiveRealEstates']),
    ...mapGetters('data', ['getAllBranches']),

    filterText() {
      if (!this.isCollapsed) {
        return this.msg('Show filter')
      } else {
        return this.msg('Hide filter')
      }
    },
    allBranches() {
      return Object.values(this.getAllBranches)
    },
    selectedFields() {
      let fields = this.fields.filter(f => f.active);
      return (fields.length > 0) ? fields : this.fields;
    },
    selectedRealEstatesIds: {
      get() {
        return this.selectedRealEstates.map(p => p.id);
      },
      set(selectedRealEstatesIds) {
        let realEstates = this.realEstates.toObject("id");
        this.selectedRealEstates = selectedRealEstatesIds.map(pid => realEstates[pid]);
      }
    },
    isAllRealEstatesSelected() {
      let ids = this.selectedRealEstates.map(p => p.id);
      return this.realEstates.filter(p => !ids.includes(p.id)).length === 0;
    },
    allRealEstateStatus() {
      return this.allStatusNoActive.map(status => ({
        value: status.value,
        text: this.msg(status.text)
      }))
    },
    allAgents() {

      return this.getAllUsers
          .filter(us => us.roleId === 18)
          .map(us => ({
            id: us.id,
            name: (us.firstname + ' ' + us.lastname)
          }))
    },
    allRealEstates() {
      return this.realEstates
    }
  },
  created() {
    let $this = this;
    this.setFields();
    this.refresh()
    this.$root.$on('crm::branch-updated', (this.listeners.refresh = () => {
      $this.refresh()
    }))
  },
  beforeDestroy() {
    this.$root.$off('crm::branch-updated', this.listeners.refresh)
  },
  methods: {
    ...mapActions('realEstate', ['getAllDisabledRealEstates', 'activateRealEstate',
      'featureRealEstate', 'deFeatureRealEstate', 'copySuccess', 'bulkEnable', 'justimmoUpdate']),

    uploadComplete() {
      // Show the toast
      this.$bvToast.toast("Files uploaded successfully!", {
        title: "Upload Complete",
        variant: "success",
        solid: true,
        autoHideDelay: 2000, // Auto-hide after 2 seconds
        toaster: "b-toaster-top-right", // Position the toast at the top-right
      });

      // Close the dialog and refresh after 2 seconds
      setTimeout(() => {
        this.dialogs.importimages = false;
        this.refresh();
      }, 2000);
      },
    uploadSuccess(file, response) {
      console.log("File uploaded successfully:", file, response);
    },
    loadDropZone() {
      this.dropzoneOptions = {
        url: window.config.apiUrl + 'realEstate/upload/imgMultiple',
        headers: {
          'Authorization': `Bearer ${localStorage.Bearer}`,
          'realEstateIds': this.selectedRealEstateIds.join(',')
        },
        paramName: 'file', // The name expected by the backend for the file parameter
        createImageThumbnails: false,
        thumbnailWidth: 200,
        thumbnailHeight: 200,
        addRemoveLinks: true,
        maxFilesize: 100, // Adjust to suitable size in MB
        maxFiles: 10, // Adjust to desired file limit
        uploadMultiple: true, // Send multiple files in a single request
        multiple: true, // Allow multiple files to be uploaded
        parallelUploads: 10, // Number of files to process in one go
        acceptedFiles: "image/*,application/pdf,.doc,.docx,.xls,.xlsx,.csv,.tsv,.ppt,.pptx,.mp4,.mp3"
      };
    },
    openImageModal() {
      this.dialogs.importimages = true;
      this.selectedRealEstateIds=[];
    },
    dismissAllPopovers() {
      this.$root.$emit('bv::hide::popover')
    },
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
    isButtonDisabled(property) {
      return property.attachments == null || property.attachments.length < 1 || property.realEstatePrices == null ||
          property.realEstatePrices.basePricePerNight == null || property.realEstatePrices.basePricePerNight < 0.01;
    },
    tooltipText(property) {
      return this.isButtonDisabled(property) ? this.msg('To activate and export please enter basis daily price and upload at least 1 image first') : '';
    },
    updateFromjustimmo() {
      let $this = this;
      if (this.buid == null) return
      this.justimmoUpdate({buid: this.buid})
          .then(() => {
            $this.refresh()
          })
    },
    bulkEnableRealEsates() {
      let $this = this;
      this.bulkEnable(this.selectedRealEstates)
          .then(() => {
            $this.refresh()
          })

    },
    toggleAllRealEstates(estate) {
      if (estate)
        this.selectedRealEstates = [...this.realEstates];
      else
        this.selectedRealEstates = [];
    },
    activateRealEstateById(realId) {
      let $this = this;
      this.activateRealEstate(realId)
          .then(() => {
            $this.refresh()
            window.open(`/real_estate`);
          })
    },
    deFeatureRealEstateById(realId) {
      let $this = this;
      this.deFeatureRealEstate(realId)
          .then(() => {
            $this.refresh()
          })
    },
    featureRealEstateById(realId) {
      let $this = this;
      this.featureRealEstate(realId)
          .then(() => {
            $this.refresh()
          })
    },
    onCopy: function (e) {
      this.copySuccess(e.text)
    },
    onError: function (e) {
      alert('Failed to copy the text to the clipboard')
    },
    setFields() {
      let $this = this
      let fields = [

        {
          key: 'updatedDate',
          label: $this.msg('Updated at'),
          sortable: true,
          active: true,
        },
        {
          key: 'id',
          label: $this.msg('Actions'),
          sortable: true,
          active: true

        },
        {
          key: 'attachments',
          label: 'Main image',
          sortable: true,
          active: true
        },
        {
          key: 'address',
          label: $this.msg('Address'),
          sortable: true,
          active: true
        },


        {
          key: 'status',
          label: $this.msg('status'),
          sortable: true,
          active: true
        },

        {
          key: 'realEstatePrices',
          label: $this.msg('Base price per night'),
          sortable: false,
          active: true
        },
        {
          key: 'realEstateArea.livingSpace',
          label: $this.msg('Living space'),
          sortable: false,
          active: true
        },
        {
          key: 'realEstateArea.totalSpace',
          label: $this.msg('Total space'),
          sortable: false,
          active: true
        },
        {
          key: 'realEstateArea.rooms',
          label: $this.msg('Rooms'),
          sortable: true,
          active: true
        },

        /*  {
            key: 'actions',
            label: $this.msg('Actions'),
            sortable: true
          },*/
      ]
      this.fields = fields;

      try {
        let cachedFields = localStorage["real-estates-fields"];
        if (cachedFields != null) {
          let cachedFieldArr = JSON.parse(cachedFields);
          let cachedFieldsMap = cachedFieldArr.toObject("key");
          fields.forEach(field => field.active = cachedFieldsMap[field.key].active != null ? cachedFieldsMap[field.key].active : true);
        }
      } catch (e) {
        localStorage.setItem("real-estates-fields", null);
      }
    },

    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.propertyId = item.id;
      this.addressName = item.address != null ? item.address + ' ' + item.house + '/' + item.apt : "Die Addresse ist leer";
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    infoFeature(item, index, button) {
      this.featureInfoModal.title = `Row index: ${index}`
      this.featureInfoModal.propertyId = item.id;
      this.addressName = item.address != null ? item.address : "Die Addresse ist leer";
      this.$root.$emit('bv::show::modal', this.featureInfoModal.id, button)
    },
    resetInfoModal() {
      this.$root.$emit('bv::hide::modal', this.infoModal.id)
    },
    resetFeatureInfoModal() {
      this.$root.$emit('bv::hide::modal', this.featureInfoModal.id)
    },
    refresh() {
      let $this = this
      this.loading = true;
      this.getAllDisabledRealEstates({
        page: $this.page.currentPage,
        amount: $this.page.amount,
        filter: $this.filter,
        sortcolumn: 'updatedDate',
        sortby: $this.sort.direction,
      })
          .then(this.onRealEstatesRetrieve, (error) => $this.error = error)
    },
    onRealEstatesRetrieve(data) {
      this.page = {
        amount: data['Amount'],
        total: data['Total'],
        currentPage: data['Page']
      };
      this.sort = {
        by: data["sortColumn"],
        direction: data["sortOrder"]
      }
      this.realEstates = data["RealEstates"];
      this.loadDropZone();

      this.loading = false;
    },
    onRealEstateSelect(id) {
      this.selected = id
      if (id === 0) {
        this.dialogs.realEstate = true
      }
    },
    onSort(data) {
      this.sort.by = data.sortBy;
      this.sort.direction = data.sortDesc ? "desc" : "asc";
      this.refresh();
    },
    onRowClick(realEstate) {
      this.$router.push(`/real_estate/edit/${realEstate.id}`);
    },
    submitRealEstate() {
      this.$root.$emit('crm::submitRealEstate')
    },
    onSubmitted(server) {
      console.log("RealEstate -> onSubmitted()", server)
      if (server.id != null)
        this.selected = server.id;
      this.refresh()
      this.dialogs.realEstate = false;
    }
  }
}
</script>


<style lang="scss" scoped>
.features-group .btn-group-toggle {
  width: 100%;

  i {
    font-size: 26px;
  }

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px dashed #f69c55;
  }
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3); /* Semi-transparent white background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Adjust the z-index as needed */
}

.loading-spinner {
  text-align: center;
}


/*.features-group .btn {
  width: 100%;
  height: 100px;
  text-align: center;
  vertical-align: middle;
  //color:  !important;
}*/

.dropzone-modern {
  border: 2px dashed #007bff;
  padding: 20px;
  border-radius: 10px;
  background: #f0f8ff;
  transition: all 0.3s ease-in-out;
}

.dark-layout .dropzone-modern {
  background: #233189;
}

.dropzone-modern:hover {
  background: #e6f7ff;
  box-shadow: 0 4px 12px rgba(0, 123, 255, 0.2);
}

.dropzone-custom-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.subtitle {
  font-size: 0.9rem;
  color: #6c757d;
}

.b-list-group-item {
  border: none;
  border-bottom: 1px solid #f1f1f1;
}

.b-list-group-item:last-child {
  border-bottom: none;
}

.shadow-lg {
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1) !important;
}
</style>
